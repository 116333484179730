<template>
  <div class="yhbh-select" v-if="yhbhList.length > 1">
    <div v-if="$slots.trigger" @click="visible = true">
      <slot name="trigger"></slot>
    </div>
    <van-button v-else class="yhbh-select__trigger-btn" @click="visible = true" :style="customStyle">切换户号</van-button>
    <van-popup class="yhbh-select__popup" v-model="visible" round close-on-popstate position="bottom" get-container="#app" >
      <div class="yhbh-select__popup-header">
        <van-nav-bar title="选择户号" left-text="取消" right-text="确定" @click-left="visible = false" @click-right="confirm"/>
      </div>
      <div class="yhbh-select__popup-body">
        <van-radio-group v-model="myValue">
          <van-cell-group>
            <van-cell :title="item.yhmc" :label="item.yhdz" v-for="(item,idx) in yhbhList" :key="idx" clickable  @click="itemClickHandle(item)">
              <template #title>
                <div>
                  <span>{{item.yhmc}}</span>&nbsp;&nbsp;
                  <span>{{item.yhbh}}</span>
                </div>
              </template>
              <template #right-icon>
                <van-radio :name="item.yhbh"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import { Divider, Radio, RadioGroup } from 'vant';
export default {
  name: 'YhbhSelect',
  components: {
    [Divider.name]: Divider,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  props:{
    value: {
      type: [String, Number],
      default: null
    },
    customStyle: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      visible: false,
      myValue: this.value,
      selectItem: null
    }
  },
  computed: {
    ...mapGetters(['yhbhList'])
  },
  methods: {
    itemClickHandle(item) {
      this.selectItem = item;
      this.myValue = item.yhbh;
      this.$emit('change', this.myValue);
    },
    confirm() {
      this.visible = false;
      this.$emit('confirm', this.selectItem);
    },
  },
  watch: {
    value: {
      handler(val) {
        this.myValue = val;
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .yhbh-select__trigger-btn {
    border: 0;
    padding: 0 16px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: inherit;
  }
  .yhbh-select__popup {
    height: 40%;
    text-align: left;
    display: flex;
    flex-direction: column;

  }
  .yhbh-select__popup-header {
    flex: 0 1 auto;
  }
  .yhbh-select__popup-body {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
  }
</style>