import fetch from '@/utils/fetch';

/* ---
根据代码种类获取字符代码
*/
export function zfdmManageService_getZfdmByDmzl(dmzl, pagination, meta) {
	return fetch({
		url : 'psdmsqxgl/services/ZfdmManageService/getZfdmByDmzl',
		method : 'post',
		data : {
			param : {
				dmzl : dmzl //String
			},
			pagination : pagination
		}
	})
}