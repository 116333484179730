// WaGen___HASHKEY__7ea8fc0c_2021-12-01 15:04:28 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
标准CRUD方法：添加记录
*/
export function gzdGzsl_add(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzsl
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：yhbh : Stringyhmc : Stringgzdh : Longjgbm : String
*/
export function gzdGzsl_update(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzsl
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：yhbh : Stringyhmc : Stringgzdh : Longjgbm : String
*/
export function gzdGzsl_remove(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzsl
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function gzdGzsl_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzsl
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function gzdGzsl_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzsl
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function gzdGzsl_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzsl
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：yhbh : Stringyhmc : Stringgzdh : Longjgbm : String
*/
export function gzdGzsl_fetch(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzsl
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function gzdGzsl_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function gzdGzsl_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //GzdGzsl
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function gzdGzsl_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzsl
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function gzdGzsl_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzsl
		}
	})
}

/* ---
未提供注释
*/
export function gzdGzsl_getGzsl(jgbm,yhmc,yhbh,sqlx,gzdzt,bxclfzr,sqrqq,sqrqz, pagination, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/getGzsl',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				yhmc : yhmc, //String
				yhbh : yhbh, //String
				sqlx : sqlx, //String
				gzdzt : gzdzt, //List<String>
				bxclfzr : bxclfzr, //String
				sqrqq : sqrqq, //String
				sqrqz : sqrqz //String
			},
			pagination : pagination
		}
	})
}

/* ---
多记录插入
*/
export function gzdGzsl_insertList(list, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<GzdGzsl>
		}
	})
}

/* ---
多记录更新,需要的主键字段：yhbh : Stringyhmc : Stringgzdh : Longjgbm : String
*/
export function gzdGzsl_updateList(list, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<GzdGzsl>
		}
	})
}

/* ---
多记录删除,需要的主键字段：yhbh : Stringyhmc : Stringgzdh : Longjgbm : String
*/
export function gzdGzsl_deleteList(list, meta) {
	return fetch({
		url : 'psdmszhwh/zhwhschema/GzdGzsl/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<GzdGzsl>
		}
	})
}

