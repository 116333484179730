<template>
  <page-view title="故障报修" left-arrow custom-class="page--whiteBg">
    <template #nav-right>
      <yhbh-select v-model="form.yhbh" @confirm="yhbhChangeHandle" :custom-style="{right: '-16px'}"/>
    </template>
    <van-form 
      :show-error="true"
      :show-error-message="false"
      :scroll-to-error="true"
      :validate-first="true"
      @submit="onSubmit" @failed="onFailed">
      
      <van-field
        name="isNil"
        label="非系统户"
        input-align="right">
        <template #input>
          <van-switch v-model="form.isNil" size="20" active-value="1" inactive-value="0" @change="handleIsNilChange"/>
        </template>
      </van-field>
      <van-field
        v-model="form.yhbh"
        name="yhbh"
        label="用户编号"
        placeholder="用户编号"
        :disabled="form.isNil == '1' ? true: false"
        :rules="[{ required: true, message: '请填写用户编号' }]"
      />
      <van-field
        v-model="form.yhmc"
        name="yhmc"
        label="用户名称"
        placeholder="用户名称"
        :rules="[{ required: true, message: '请填写用户名称' }]"
      />
      <van-field
        name="yhdh"
        v-model="form.yhdh"
        label="联系方式"
        placeholder="联系方式"
        :rules="[{ required: true, message: '请填写联系方式' }]"
      />
      <van-field
        name="yhdz"
        v-model="form.yhdz"
        rows="2"
        autosize
        label="用户地址"
        type="textarea"
        placeholder="用户地址"
        show-word-limit
        :rules="[{required: true, message: '请填写用户地址'}]"
      />

      
      <vant-datetime-select
        name="sqsj"
        v-model="form.sqsj"
        label="诉求时间"
        placeholder="请选择故诉求时间"
       />
      <vant-select 
        v-model="form.sqlx"
        name="sqlx"
        label="诉求类型"
        placeholder="请选择诉求类型"
        :columns="sqlxList"
      />
      <van-field
        name="bxnr"
        v-model="form.bxnr"
        rows="2"
        autosize
        label="诉求内容"
        type="textarea"
        placeholder="诉求内容"
        show-word-limit
        :rules="[{required: true, message: '请填写诉求内容'}]"
      />
     
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </page-view>
</template>

<script>
import { mapGetters } from 'vuex';

import YhbhSelect from '@/components/YhbhSelect/index.vue';
import VantSelect from '@/components/VantSelect/index.vue';
import VantDatetimeSelect from '@/components/VantDatetimeSelect/index.vue';

import {
  zfdmManageService_getZfdmByDmzl
} from '@/api/zfdm'
import { gzdGzsl_add } from '@/api/psdmszhwh/zhwhschema/GzdGzslAPI';

export default {
  name: 'gzbx',
  components: {
    YhbhSelect,
    VantSelect,
    VantDatetimeSelect
  },
  data() {
    return {
      form: {
        isNil: '0',
        yhbh: '',
        yhmc: '',
        yhdz: '',
        yhdh: '',

        sqsj: new Date(),
        sqlx: '0',
        bxnr: ''
      },
      zfdmOptions: {
        'SQLX': [], // 诉求类型
      }
    }
  },
  computed: {
    ...mapGetters(['jgbm','czyh']),
    sqlxList() {
      return this.zfdmOptions['SQLX'].map(item => {
        return {
          label: item.dmnr,
          value: item.dmbh
        }
      })
    }
  },
  methods: {
    yhbhChangeHandle(yhxx) {
      this.form.isNil = '0';
      this.form.yhbh = yhxx.yhbh;
      this.form.yhmc = yhxx.yhmc;
      this.form.yhdz = yhxx.yhdz;
      this.form.yhdh = yhxx.lxsj || yhxx.lxdh;
    },
    handleIsNilChange(val) {
      if (val == '1') {
        this.form.yhbh = '非系统用户';
      } else {
        this.form.yhbh = '';
        this.form.yhmc = '';
        this.form.yhdh = '';
        this.form.yhdz = '';
      }
    },
    resetForm() {
      this.form = {
        isNil: '0',
        yhbh: '',
        yhmc: '',
        yhdz: '',
        yhdh: '',

        sqsj: new Date(),
        sqlx: '0',
        bxnr: ''
      };
    },
    onSubmit(values) {
      this.$toast.loading('正在提交...'),
      console.log('submit', values);

      let params = values;
      params.jgbm = this.jgbm;
      params.bxslr = "微信公众号";
      params.bxslrid = this.czyh;
      params.gzdh = Date.now();
      params.gzdzt = '0';

      gzdGzsl_add(params).then(res => {
        this.$toast.clear();
        this.resetForm();
        this.$toast("已提交");
        console.log(res)
      }).catch(err => {
        this.$toast.clear();
        this.$toast("报修信息提交失败:"+err.message);
      });
    },
    onFailed({values, errors}) {
      console.log('failed:, Errors:', errors)
      this.$notify({ type: 'danger', message: errors[0].message});
    },
  },
  mounted() {
    
    for(let key in this.zfdmOptions) {
      zfdmManageService_getZfdmByDmzl(key).then(response => {
        this.zfdmOptions[key] = response.content;
      });
    }
  }
}
</script>